.footer {
  // position: absolute;
  // bottom: 0;
  // width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 110px;
  background-color: var(--accent-color);
}

.footer__text {
  margin: 0 auto;
  font-family: var(--gallery-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--white-color);
  @include mq(fab) {
      width: 380px;

    }
}

.footer__wrap {
  text-transform: uppercase;
}

.footer__link {
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  color: var(--white-color)
}

// scroll up

.back-to-top {
  position: fixed;
  border-radius: 50%;
  right: -13px;
  bottom: -25px;
  padding: 10px 12px;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0;
  @include mq(tab) {
    right: -1px;
  }
  @include mq(desc) {
    right: 10px;
  }
}

.back-to-top.active {
  // margin-bottom: 40px;
  // margin-right: 20px;
  bottom: 100px;
  transition: all 0.3s;
  opacity: 1;
  fill: var(--accent-color);
  @include mq(desc) {
    margin-right: 20px;
  }
}

