.backdrop-team {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  transition: opacity 250ms linear, visibility 250ms linear;
}

.backdrop-team .visually-hidden .modal-team {
  transform: translate(-50%, -50%) scale(0) rotate(-180deg);
}

.backdrop-team ::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}

.backdrop-team ::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.backdrop-team ::-webkit-scrollbar-thumb {
  background: #FD5103;
  border-radius: 4px;
}

.modal-team {
  position: absolute;
  width: calc(100% - 20px);
  height: 600px;
  bottom: 110px;
  left: 50%;
  transform: translate(-50%) scale(1) rotate(0);
  background-color: var(--modal-bg-color);
  border-radius: 4px;
  transition: cubic-bezier(0.4, 0, 0.2, 1);
  overflow: auto;
  @include mq(fab) {
    width: calc(100% - 20px);
  }
  @include mq(tab) {
    width: calc(100% - 20px);
    height: 500px;
  }
  @include mq(desk) {
    width: calc(100% - 30px);
    height: 600px;
    overflow: hidden;
  }
}

.modal__team-close-btn {
  position: sticky;
  top: 15px;
  left: 90%;
  border-radius: 50%;
  border: 0;
  background-color: transparent;
  fill: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  transition: var(--transition);
  @include mq(desk) {
    left: 95%;
  }
}

.team__wrap {
  margin: 25px 16px;
}

.team__title {
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.91;
  color: var(--accent-color);
  margin-bottom: 40px;

  @include mq(fab) {
    font-weight: 500;
    font-size: 32px;
  }

  @include mq(tab) {
    font-weight: 500;
    font-size: 32px;
  }

  @include mq(desk) {
    font-weight: 500;
    font-size: 38px;
    line-height: 1.5;
  }
}

.team__list {
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq(tab) {
    --card-item: 7;
    --card-gap: 37px;
  }

  @include mq(desk) {
    --card-item: 7;
    --card-gap: 70px;
  }
}

.team__list-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.team__img {
  width: 132px;
  height: 132px;
  border-radius: 100px;
  display: block;
}

.workers__title {
  color: var(--title-light-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 1.18;
  //text-align: center;
  letter-spacing: 0.02em;
  margin-top: 25px;

}

.workers__title:hover,
.workers__title:focus {
  color: var(--accent-color);
}



