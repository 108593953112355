.container {
  //   width: 1200px;
  width: 100%;
  margin: 0 auto;

  @include mq(mob) {
    width: 320px;
    padding-right: 20px;
    padding-left: 20px;
  }

  @include mq(fab) {
    width: 480px;
    
  }

  @include mq(tab) {
    width: 768px;
    padding-right: 32px;
    padding-left: 32px;
  }

  @include mq(desk) {
    padding-right: 16px;
    padding-left: 16px;
    width: 1280px;
  }
}
