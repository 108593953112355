.dark-theme {
  --body-background-color: #202025;
  --title-light-color: #fcfcfc;
  --accent-theme-color: #fcfcfc;
  --theme-dark-color: #fd5103;
  --manu-theme-color: #fcfcfc;
  --search-input-color: #202025;
  --input-text-color: #fcfcfc;
  --btn-mob-menu-color: #fcfcfc;
  --fav-dropdown-item: #fcfcfc;

  --hero-text-color: #fcfcfc;
  --hero-bg-input-color: #202025;
  --select-input-color: #202025;

  --gallery-btn-fav-color: #202025;
  --gallery-title-color: #fcfcfc;
  --gallery-subtitle-color: #fcfcfc;
  --modal-bg-color: #202025;
  --modal-title-color: #fcfcfc;
  --modal-text-color: #BDBDBD;
  --modal-accent-text-color: #fcfcfc;
  --background-color: rgba(255, 255, 255, 0.2);
  --modal-close-icon-color: #FCFCFC;

  --mob-menu-bg-color: #202025;
  --menu-mob-color:#fcfcfc;
  --heart-color: #202025;
}
