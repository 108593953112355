html {
  scroll-behavior: smooth;
}
body {
  min-height: 100%;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  background-color: var(--body-background-color);
  box-sizing: border-box;
}
.overflow-hidden {
  overflow: hidden;
}

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.91;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
