.section {
  padding-top: 60px;
  padding-bottom: 40px;
  @include mq(tab) {
    padding-top: 68px;
    padding-bottom: 60px;
  }
  @include mq(desk) {
    padding-top: 37px;
    padding-bottom: 60px;
  }
}

.section__title {
  text-align: center;
  color: var(--title-color);
  font-family: var(--title-font-family);

  @include mq(tab) {
    font-weight: 700px;
    font-size: 32px;
    line-height: 1.31;
  }

  @include mq(desk) {
    font-size: 48px;
    line-height: 1.5;
  }
}
