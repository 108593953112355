*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

p {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

button {
  cursor: pointer;
}

article {
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  display: block;
}

:root {
  --title-font-family: "Poppins", sans-serif;
  --primary-font-family: "Poppins", sans-serif;
  --gallery-font-family: "Roboto", sans-serif;
  --light-bg-color: #fcfcfc;
  --dark-bg-color: #202025;
  --white-color: #fcfcfc;
  --black-color: #000;
  --medium-grey-color: #5f6775;
  --title-light-color: #202025;
  --title-dark-color: #fcfcfc;
  --filter-letter-light-color: #5f6775;
  --filter-letter-dark-color: #5f6775;
  --modal-text-light-color: #5f6775;
  --modal-text-dark-color: #fcfcfc;
  --modal-title--black: #000917;
  --btn-bg-color: #fe7031;
  --btn-inmodal-bg-color: #fd5103;
  --btn-text-color: #fd5103;
  --btn-outline-color: #ff8650;
  --pretitle-hero-color: #fd5103;
  --accent-color: #fd5103;
  --btn-hover-color: #fd5103;
  --card-item: 1;
  --card-gap: 20px;
  --transition: 250ms cubic-bezier(.4, 0, .2, 1);
  --body-background-color: #fff;
  --accent-theme-color: #fd5103;
  --theme-dark-color: #5f6775;
  --manu-theme-color: #202025;
  --search-input-color: #fff;
  --input-text-color: #202025;
  --btn-mob-menu-color: #202025;
  --fav-dropdown-item: #202025;
  --hero-text-color: #202025;
  --hero-bg-input-color: #fcfcfc;
  --select-input-color: #fcfcfc;
  --gallery-btn-fav-color: #fff;
  --gallery-title-color: #000;
  --gallery-subtitle-color: #5f6775;
  --modal-bg-color: #fff;
  --modal-title-color: #000917;
  --modal-text-color: #5f6775;
  --modal-accent-text-color: #000;
  --background-color: #00000080;
  --modal-close-icon-color: #202025;
  --mob-menu-bg-color: #fcfcfc;
  --menu-mob-color: #202025;
  --heart-color: #fcfcfc;
}

.dark-theme {
  --body-background-color: #202025;
  --title-light-color: #fcfcfc;
  --accent-theme-color: #fcfcfc;
  --theme-dark-color: #fd5103;
  --manu-theme-color: #fcfcfc;
  --search-input-color: #202025;
  --input-text-color: #fcfcfc;
  --btn-mob-menu-color: #fcfcfc;
  --fav-dropdown-item: #fcfcfc;
  --hero-text-color: #fcfcfc;
  --hero-bg-input-color: #202025;
  --select-input-color: #202025;
  --gallery-btn-fav-color: #202025;
  --gallery-title-color: #fcfcfc;
  --gallery-subtitle-color: #fcfcfc;
  --modal-bg-color: #202025;
  --modal-title-color: #fcfcfc;
  --modal-text-color: #bdbdbd;
  --modal-accent-text-color: #fcfcfc;
  --background-color: #fff3;
  --modal-close-icon-color: #fcfcfc;
  --mob-menu-bg-color: #202025;
  --menu-mob-color: #fcfcfc;
  --heart-color: #202025;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  background-color: var(--body-background-color);
  box-sizing: border-box;
  margin: 0;
  font-style: normal;
  font-weight: 500;
}

.overflow-hidden {
  overflow: hidden;
}

.page {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

footer {
  margin-top: auto;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.91;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.card-set {
  gap: var(--card-gap);
  flex-wrap: wrap;
  display: flex;
}

.card-set-item {
  flex-basis: calc((100% - (var(--card-item)  - 1) * var(--card-gap)) / var(--card-item));
}

.container {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 320px) {
  .container {
    width: 320px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 480px) {
  .container {
    width: 480px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 768px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (min-width: 1280px) {
  .container {
    width: 1280px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.section {
  padding-top: 60px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .section {
    padding-top: 68px;
    padding-bottom: 60px;
  }
}

@media (min-width: 1280px) {
  .section {
    padding-top: 37px;
    padding-bottom: 60px;
  }
}

.section__title {
  text-align: center;
  color: var(--title-color);
  font-family: var(--title-font-family);
}

@media (min-width: 768px) {
  .section__title {
    font-weight: 700px;
    font-size: 32px;
    line-height: 1.31;
  }
}

@media (min-width: 1280px) {
  .section__title {
    font-size: 48px;
    line-height: 1.5;
  }
}

.btn {
  text-align: center;
  font-family: inherit;
  font-style: inherit;
  border-width: 0;
  border-color: #0000;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

#runaway-btn {
  min-height: 50px;
  min-width: 200px;
  color: var(--white-color);
  box-shadow: 1px 1px 5px var(--white-color);
  background-color: var(--accent-color);
  border: none;
  border-radius: 5px;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1279.98px) {
  #runaway-btn {
    display: none;
  }
}

.backdrop__mobile {
  z-index: 15;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
}

@media (min-width: 320px) {
  .backdrop__mobile {
    -webkit-backdrop-filter: blur(4.5px);
    backdrop-filter: blur(4.5px);
    -webkit-backdrop-filter: blur(4.5px);
    background: #ffffff40;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 20px 20px 40px -6px #0003;
  }
}

.backdrop__mobile.is-open {
  overflow-y: none;
  display: flex;
}

.backdrop__mobile.is-hidden {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  overflow-y: none;
}

.menu__container {
  width: 100vw;
  height: 70%;
  background-color: var(--mob-menu-bg-color);
  transition: all var(--menu-transition);
  flex-direction: column;
  padding: 32px 20px;
  display: flex;
  position: relative;
}

.btn__burger-menu-toggle {
  z-index: 25;
  width: 40px;
  height: 40px;
  fill: var(--menu-mob-color);
  transition: all var(--menu-transition);
  background-color: #0000;
  border-radius: 50%;
  margin-left: auto;
  padding: 0;
  display: flex;
  position: static;
}

@media (min-width: 1280px) {
  .btn__burger-menu-toggle {
    display: none;
  }
}

.btn__burger-menu-toggle .menu__open-icon {
  opacity: 1;
}

.btn__burger-menu-toggle .menu__close-icon, .btn__burger-menu-toggle.is-active .menu__open-icon {
  opacity: 0;
}

.btn__burger-menu-toggle.is-active .menu__close-icon {
  opacity: 1;
}

.btn__burger-menu-toggle:hover, .btn__burger-menu-toggle:focus {
  filter: drop-shadow(-2px 2px 2px #6f6e6e);
}

.menu-toggle__icon {
  transition: all var(--menu-transition);
  fill: var(--btn-mob-menu-color);
}

.btn__burger-menu-toggle:hover svg, .btn__burger-menu-toggle:focus svg {
  fill: var(--accent-color);
}

.menu__header {
  align-items: flex-start;
  display: flex;
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .menu__header {
    display: flex;
  }
}

.menu__logo {
  transition: all var(--menu-transition);
  display: none;
}

.menu__logo:hover, .menu__logo:focus {
  filter: drop-shadow(-2px 2px 2px #6f6e6e);
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .menu__logo {
    font-family: var(--title-font-family);
    letter-spacing: .02em;
    color: var(--accent-color);
    margin-right: 40px;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.1;
    display: flex;
  }
}

.menu__list {
  color: var(--hero-text-color);
  flex-direction: column;
  font-size: 28px;
  font-weight: 400;
  line-height: .86;
  display: flex;
}

.menu__theme-list {
  gap: 8px;
  margin-bottom: 40px;
  margin-right: 10px;
  display: flex;
}

.menu__theme-item {
  display: flex;
}

.menu__theme-light {
  font-family: var(--title-font-family);
  color: var(--accent-theme-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
}

.menu__switcher {
  width: 40px;
  height: 20px;
  background: #fff;
  border: .604701px solid #fd5103;
  border-radius: 20px;
}

.menu__theme-dark {
  font-family: var(--title-font-family);
  color: var(--theme-dark-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
}

.menu__login-btn {
  font-family: var(--title-font-family);
  background-color: var(--accent-color);
  color: var(--body-background-color);
  margin-left: 10px;
  padding: 3px 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
  display: flex;
  position: relative;
  top: -3px;
}

@media (min-width: 768px) {
  .menu__login-btn {
    margin-left: 40px;
    padding: 5px 15px;
  }
}

.menu__login-btn:hover, .menu__login-btn:focus {
  box-shadow: -2px 2px 4px #6f6e6e;
}

.menu__search {
  margin-bottom: 40px;
  position: relative;
}

@media (min-width: 768px) {
  .menu__search {
    display: none;
  }
}

.menu__search-icon {
  position: absolute;
  top: 20px;
  left: 8px;
  transform: translateY(-50%);
}

.menu__search-input {
  max-width: 90%;
  height: 40px;
  color: var(--input-text-color);
  background-color: var(--search-input-color);
  border: 1px solid #fd5103;
  border-radius: 5px;
  outline: none;
  padding: 8px 8px 8px 48px;
}

@media (min-width: 480px) {
  .menu__search-input {
    width: 50%;
  }
}

.menu__search-btn {
  width: 40px;
  height: 40px;
  background-color: #0000;
  border: 1px;
  position: absolute;
  left: 0;
}

.menu__search-border:after {
  content: "";
  width: 39px;
  height: 1px;
  background-color: var(--accent-color);
  position: absolute;
  top: 20px;
  left: 20px;
  transform: rotate(90deg);
}

.menu__nav-link {
  color: var(--menu-mob-colorg);
  background-color: #0000;
  border: 0;
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .menu__nav-list {
    flex-flow: column wrap;
    align-content: center;
    display: flex;
  }
}

.menu__nav-item {
  color: var(--menu-mob-color);
  transition: all var(--menu-transition);
  font-size: 28px;
  font-weight: 400;
  line-height: .86;
}

.menu__nav-item:before {
  content: "♥";
  color: #0000;
}

.menu__nav-item:not(:last-child) {
  margin-bottom: 32px;
}

.menu__nav-item:hover, .menu__nav-item:focus {
  color: var(--accent-color);
  filter: drop-shadow(-2px 2px 2px #6f6e6e);
}

.menu__fav-item:before {
  content: "♥";
  color: var(--accent-color);
}

.backdrop__cocktail {
  width: 100%;
  height: 100%;
  background: var(--background-color);
  position: fixed;
  top: 0;
  left: 0;
}

.modal__ingredient, .modal__cocktail {
  background: var(--modal-bg-color);
  overflow: overlay;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 3px #0000001f, 0 1px 1px #00000024, 0 2px 1px #0003;
}

.modal__cocktail {
  min-width: 320px;
  height: 600px;
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .modal__cocktail {
    width: 768px;
    height: 598px;
  }
}

@media (min-width: 1280px) {
  .modal__cocktail {
    width: 822px;
    height: 598px;
  }
}

.modal__ingredient {
  width: 320px;
  height: 600px;
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .modal__ingredient {
    width: 768px;
    height: 598px;
  }
}

@media (min-width: 1280px) {
  .modal__ingredient {
    width: 715px;
    height: 598px;
  }
}

@media (min-width: 768px) {
  .modal__wrap {
    align-items: flex-start;
    display: flex;
  }
}

.cocktail__img {
  width: 320px;
  height: 320px;
}

@media (min-width: 768px) {
  .cocktail__img {
    width: 288px;
    height: 320px;
  }
}

.cocktail__modal-btn {
  margin-bottom: 28px;
  margin-left: 32px;
}

@media (min-width: 768px) {
  .cocktail__modal-btn {
    justify-content: flex-end;
    margin-bottom: 28px;
    margin-right: 28px;
    display: flex;
  }
}

.modal__btn {
  text-align: center;
  height: 48px;
  font-family: var(--gallery-font-family);
  letter-spacing: .02em;
  color: var(--white-color);
  background: var(--btn-text-color);
  border-color: #0000;
  border-radius: 4px;
  margin-top: 20px;
  padding: 0 24px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.modal__btn:hover, .modal__btn:focus {
  background: var(--btn-bg-color);
  color: var(--white-color);
}

.modal__btn:active {
  color: var(--btn-bg-color);
  background: var(--btn-bg-color);
}

.ingredient__name, .cocktail__name {
  font-family: var(--gallery-font-family);
  color: var(--modal-title-color);
  margin-top: 40px;
  margin-left: 32px;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.2;
}

.ingredient__title, .cocktail__title {
  font-family: var(--primary-font-family);
  text-transform: uppercase;
  color: var(--modal-title-color);
  margin-top: 8px;
  margin-left: 32px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.cocktail__text {
  font-family: var(--primary-font-family);
  color: var(--modal-title-color);
  margin-top: 8px;
  margin-left: 32px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

.ingredient {
  height: 160px;
  max-width: 320px;
  margin-top: 8px;
  margin-bottom: 43px;
  margin-left: 32px;
}

@media (min-width: 1280px) {
  .ingredient {
    min-width: 430px;
    overflow: overlay;
  }
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .ingredient {
    min-width: 448px;
  }
}

.ingredient__item {
  font-family: var(--primary-font-family);
  color: var(--modal-text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
}

.ingredient__accent {
  color: var(--modal-title-color);
}

.ingredient-link {
  cursor: pointer;
}

.ingredient-link:hover {
  color: var(--accent-color);
}

.recipe__wrap {
  margin-left: 28px;
}

.recipe__title {
  font-family: var(--primary-font-family);
  text-transform: uppercase;
  color: var(--modal-title-color);
  margin-top: 28px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
}

.recipe__text {
  max-width: 722px;
  font-family: var(--primary-font-family);
  color: var(--modal-text-color);
  overflow: overlay;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
}

@media (min-width: 768px) {
  .recipe__text {
    max-height: 90px;
  }
}

.btn--close {
  fill: var(--accent-color);
  background-color: #0000;
  border: #0000;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: auto;
  padding: 0;
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
}

.accent__text {
  color: var(--modal-accent-text-color);
  font-weight: 600;
}

.description__text {
  max-width: 654px;
  font-family: var(--primary-font-family);
  color: var(--modal-text-color);
  margin-top: 28px;
  margin-left: 32px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.characteristic {
  margin-top: 28px;
  margin-bottom: 40px;
  margin-left: 28px;
}

.view {
  margin-bottom: 40px;
}

.line {
  border-bottom: 1px solid var(--accent-color);
  min-width: 320px;
}

@media (min-width: 1280px) {
  .line {
    min-width: 715px;
  }
}

.backdrop__cocktail ::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}

.backdrop__cocktail ::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.backdrop__cocktail ::-webkit-scrollbar-thumb {
  background: #fd5103;
  border-radius: 4px;
}

.ing_card_wrap {
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.backdrop-sign-in {
  width: 100%;
  height: 100%;
  background-color: #0003;
  transition: opacity .25s linear, visibility .25s linear;
  position: fixed;
  top: 0;
  left: 0;
}

.backdrop-sign-in .visually-hidden .modal {
  transform: translate(-50%, -50%)scale(0)rotate(-180deg);
}

.modal {
  transform: translate(-50%) scale(1) rotate(0);
  background-color: #fff;
  border-radius: 20%;
  padding: 40px;
  transition: all cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 93px;
  left: 50%;
}

@media screen and (min-width: 768px) {
  .modal {
    top: 208px;
  }
}

@media screen and (min-width: 1200px) {
  .modal {
    padding: 50px 40px;
    top: 221px;
  }
}

.modal__close-btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: var(--transition);
  background-color: #fff;
  border: 1px solid #0000001a;
  border-radius: 50%;
  outline: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
}

.modal__close-btn:hover, .modal__close-btn:focus {
  fill: var(--white-color);
  background-color: var(--accent-color);
}

.modal__title {
  font-family: var(--title-font-family);
  text-align: center;
  letter-spacing: .03em;
  color: #212121;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (min-width: 1200px) {
  .modal__title {
    font-size: 20px;
    line-height: 1.15;
  }
}

.modal-label {
  flex-direction: column;
  display: flex;
}

.modal-label:not(:last-of-type) {
  margin-bottom: 10px;
}

.modal-label:last-of-type {
  margin-bottom: 20px;
}

.modal-label__text {
  font-family: var(--font-main);
  letter-spacing: .01em;
  color: #757575;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.17;
}

.modal-label__input {
  transition: border var(--transition);
  width: 250px;
  height: 30px;
  border: 1px solid #21212133;
  border-radius: 4px;
  outline: none;
  padding: 5px 20px;
}

.modal-label__input:hover, .modal-label__input:focus {
  border: 1px solid var(--accent-color);
  cursor: pointer;
  outline: none;
}

.modal-policy {
  margin-bottom: 30px;
}

.modal-policy__label {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.modal-policy__text {
  font-family: var(--font-main);
  letter-spacing: .03em;
  color: #757575;
  font-size: 12px;
  line-height: 1.17;
}

@media screen and (min-width: 1200px) {
  .modal-policy__text {
    font-size: 14px;
    line-height: 1.71;
  }
}

.modal-policy__link {
  color: var(--accent-color);
  border-bottom: 1px solid var(--accent-color);
  text-decoration: none;
}

.modal-policy__custom-check {
  width: 16px;
  height: 15px;
  border: 2px solid #212121;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  display: flex;
}

.modal-policy__check {
  transition: border var(--transition);
}

.modal-policy__check:checked + .modal-policy__custom-check {
  border: 2px solid var(--accent-color);
  background-color: var(--accent-color);
}

.modal-policy__check:hover + .modal-policy__custom-check, .modal-policy__check:focus + .modal-policy__custom-check {
  border: 2px solid var(--accent-color);
}

.sign-in-btns {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.modal-button-sign-in {
  font-family: var(--font-main);
  background-color: var(--accent-color);
  color: #fff;
  text-align: center;
  letter-spacing: .06em;
  cursor: pointer;
  transition: background-color var(--transition);
  border: 0;
  border-radius: 4px;
  align-items: center;
  margin: 0 auto;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.88;
  display: block;
}

.modal-button-sign-in:hover, .modal-button-sign-in:focus {
  background-color: var(--accent-color);
  box-shadow: 0 3px 1px #0000001a, 0 1px 2px #00000014, 0 2px 2px #0000001f;
}

.modal__bg {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__annoy {
  width: 300px;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  font-size: 1.5rem;
  animation-name: animatemodal;
  animation-duration: .4s;
  display: flex;
  position: absolute;
  bottom: 40px;
  right: 10px;
  box-shadow: 0 1px 6px #20212447;
}

@media (min-width: 480px) {
  .modal__annoy {
    width: 400px;
    right: 40px;
  }
}

.close__btn {
  align-self: flex-end;
}

.close__btn:hover {
  cursor: pointer;
  color: red;
}

.modal__img-soldier {
  margin-bottom: 15px;
}

.modal__img-heart {
  margin-left: 7px;
}

@media (min-width: 320px) {
  .modal__img-heart {
    width: 30px;
    height: 30px;
  }
}

.modal-annoy__text {
  font-family: var(--primary-font-family);
  color: #000;
  margin-bottom: 15px;
  font-size: 16px;
  display: flex;
}

.modal-annoy__link {
  font-family: var(--primary-font-family);
  color: #212121;
  margin-bottom: 30px;
  font-size: 14px;
  text-decoration: underline;
}

.modal-annoy__link:hover {
  color: #fd5103;
}

@keyframes animatemodal {
  from {
    opacity: 0;
    bottom: -100px;
  }

  to {
    opacity: 1;
    bottom: 20px;
  }
}

.backdrop-team {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  transition: opacity .25s linear, visibility .25s linear;
  position: fixed;
  top: 0;
  left: 0;
}

.backdrop-team .visually-hidden .modal-team {
  transform: translate(-50%, -50%)scale(0)rotate(-180deg);
}

.backdrop-team ::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}

.backdrop-team ::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.backdrop-team ::-webkit-scrollbar-thumb {
  background: #fd5103;
  border-radius: 4px;
}

.modal-team {
  width: calc(100% - 20px);
  height: 600px;
  transform: translate(-50%) scale(1) rotate(0);
  background-color: var(--modal-bg-color);
  border-radius: 4px;
  transition: all cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  bottom: 110px;
  left: 50%;
  overflow: auto;
}

@media (min-width: 480px) {
  .modal-team {
    width: calc(100% - 20px);
  }
}

@media (min-width: 768px) {
  .modal-team {
    width: calc(100% - 20px);
    height: 500px;
  }
}

@media (min-width: 1280px) {
  .modal-team {
    width: calc(100% - 30px);
    height: 600px;
    overflow: hidden;
  }
}

.modal__team-close-btn {
  fill: var(--accent-color);
  cursor: pointer;
  transition: var(--transition);
  background-color: #0000;
  border: 0;
  border-radius: 50%;
  outline: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
  left: 90%;
}

@media (min-width: 1280px) {
  .modal__team-close-btn {
    left: 95%;
  }
}

.team__wrap {
  margin: 25px 16px;
}

.team__title {
  text-align: center;
  color: var(--accent-color);
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.91;
}

@media (min-width: 480px) {
  .team__title {
    font-size: 32px;
    font-weight: 500;
  }
}

@media (min-width: 768px) {
  .team__title {
    font-size: 32px;
    font-weight: 500;
  }
}

@media (min-width: 1280px) {
  .team__title {
    font-size: 38px;
    font-weight: 500;
    line-height: 1.5;
  }
}

.team__list {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .team__list {
    --card-item: 7;
    --card-gap: 37px;
  }
}

@media (min-width: 1280px) {
  .team__list {
    --card-item: 7;
    --card-gap: 70px;
  }
}

.team__list-item {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.team__img {
  width: 132px;
  height: 132px;
  border-radius: 100px;
  display: block;
}

.workers__title {
  color: var(--title-light-color);
  font-family: var(--title-font-family);
  letter-spacing: .02em;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.18;
}

.workers__title:hover, .workers__title:focus {
  color: var(--accent-color);
}

.fav-ing {
  font-family: var(--fav-ing-font-family);
}

.fav-ing-section {
  margin-top: 80px;
  margin-bottom: 55px;
}

.fav-ing__title {
  text-align: center;
  color: var(--title-light-color);
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.91;
}

@media (min-width: 480px) {
  .fav-ing__title {
    font-size: 32px;
    font-weight: 700;
  }
}

@media (min-width: 768px) {
  .fav-ing__title {
    font-size: 32px;
    font-weight: 700;
  }
}

@media (min-width: 1280px) {
  .fav-ing__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.5;
  }
}

.fav-ing__list {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 768px) {
  .fav-ing__list {
    --card-item: 2;
    --card-gap: 20px;
  }
}

@media (min-width: 1280px) {
  .fav-ing__list {
    --card-item: 3;
    --card-gap: 20px;
  }
}

.fav-ing__img {
  width: 100%;
  border-radius: 3px 3px 0 0;
  margin-bottom: 20px;
  display: block;
}

.fav-ing__list-name {
  text-align: center;
  color: var(--title-light-color);
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.2;
}

@media (min-width: 480px) {
  .fav-ing__list-name {
    margin-bottom: 15px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .fav-ing__list-name {
    margin-bottom: 15px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .fav-ing__list-name {
    margin-bottom: 15px;
    font-size: 32px;
    font-weight: 900;
    line-height: 1.2;
  }
}

.fav-ing__list-item {
  border: 1px solid var(--accent-color);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
}

.fav-ing__list-name {
  text-align: inherit;
  color: var(--gallery-title-color);
  margin-bottom: 15px;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.6;
}

@media (min-width: 480px) {
  .fav-ing__list-name {
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .fav-ing__list-name {
    margin-bottom: 28px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .fav-ing__list-name {
    margin-bottom: 28px;
    font-size: 32px;
    font-weight: 900;
    line-height: 1.2;
  }
}

.fav-ing__list-type {
  font-family: var(--title-font-family);
  text-transform: uppercase;
  color: var(--gallery-subtitle-color);
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}

@media (min-width: 480px) {
  .fav-ing__list-type {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  .fav-ing__list-type {
    padding-bottom: 28px;
    font-size: 16px;
    font-weight: 900;
    line-height: 1.5;
  }
}

@media (min-width: 1280px) {
  .fav-ing__list-type {
    padding-bottom: 28px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }
}

.fav-ing__btn-container {
  justify-content: space-between;
  display: flex;
}

.fav-ing__btn {
  text-align: center;
  letter-spacing: .02em;
  border-color: #0000;
  border-radius: 4px;
  outline: #0000;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
}

@media (min-width: 480px) {
  .fav-ing__btn {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .fav-ing__btn {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .fav-ing__btn {
    font-size: 20px;
    line-height: 1.2;
  }
}

.fav-ing__btn-more {
  color: var(--white-color);
  background-color: var(--btn-bg-color);
}

.fav-ing__btn-more:hover, .fav-ing__btn-more:focus {
  background-color: var(--btn-hover-color);
  color: var(--white-color);
}

.fav-ing__btn-fav {
  color: var(--btn-bg-color);
  background-color: var(--fav-ing-btn-fav-color);
  border: 1px solid var(--btn-outline-color);
}

.fav-ing__btn-fav:hover, .fav-ing__btn-fav:focus {
  outline: 1px solid var(--btn-hover-color);
  color: var(--btn-hover-color);
}

.fav-ingredient-container {
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.ing_card_wrap_modal {
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.fav-cock {
  font-family: var(--fav-cock-font-family);
}

.fav-cock-section {
  margin-bottom: 60px;
}

.fav-cock__title {
  text-align: center;
  color: var(--title-light-color);
  margin-top: 70px;
  margin-bottom: 60px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.91;
}

@media (min-width: 480px) {
  .fav-cock__title {
    font-size: 32px;
    font-weight: 700;
  }
}

@media (min-width: 768px) {
  .fav-cock__title {
    font-size: 32px;
    font-weight: 700;
  }
}

@media (min-width: 1280px) {
  .fav-cock__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.5;
  }
}

.fav-cock__list {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 768px) {
  .fav-cock__list {
    --card-item: 2;
    --card-gap: 20px;
  }
}

@media (min-width: 1280px) {
  .fav-cock__list {
    --card-item: 3;
    --card-gap: 20px;
  }
}

.fav-cock__img {
  width: 100%;
  border-radius: 3px 3px 0 0;
  margin-bottom: 20px;
  display: block;
}

.fav-cock__list-name {
  text-align: center;
  color: var(--title-light-color);
  margin-bottom: 25px;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.2;
}

@media (min-width: 480px) {
  .fav-cock__list-name {
    margin-bottom: 25px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .fav-cock__list-name {
    margin-bottom: 25px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .fav-cock__list-name {
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: 900;
    line-height: 1.2;
  }
}

.fav-cock__list-item {
  border: 1px solid var(--accent-color);
  box-sizing: border-box;
  border-radius: 4px;
}

.fav-cock__list-ingr {
  display: flex;
}

@media (min-width: 768px) {
  .fav-cock__list-ingr {
    --card-item: 2;
    --card-gap: 20px;
  }
}

@media (min-width: 1280px) {
  .fav-cock__list-ingr {
    --card-item: 3;
    --card-gap: 20px;
  }
}

.fav-cock__list-ingr .fav-cock__list-item {
  padding: 20px;
}

.fav-cock__list-ingr .fav-cock__list-name {
  text-align: inherit;
  color: var(--fav-cock-title-color);
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.6;
}

@media (min-width: 480px) {
  .fav-cock__list-ingr .fav-cock__list-name {
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .fav-cock__list-ingr .fav-cock__list-name {
    margin-bottom: 28px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .fav-cock__list-ingr .fav-cock__list-name {
    margin-bottom: 28px;
    font-size: 32px;
    font-weight: 900;
    line-height: 1.2;
  }
}

.fav-cock__list-ingr .fav-cock__list-type {
  font-family: var(--title-font-family);
  text-transform: uppercase;
  color: var(--fav-cock-subtitle-color);
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}

@media (min-width: 480px) {
  .fav-cock__list-ingr .fav-cock__list-type {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  .fav-cock__list-ingr .fav-cock__list-type {
    padding-bottom: 28px;
    font-size: 16px;
    font-weight: 900;
    line-height: 1.5;
  }
}

@media (min-width: 1280px) {
  .fav-cock__list-ingr .fav-cock__list-type {
    padding-bottom: 28px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }
}

.fav-cock__btn-container {
  justify-content: space-evenly;
  padding-bottom: 20px;
  display: flex;
}

.fav-cock__btn {
  text-align: center;
  letter-spacing: .02em;
  border-color: #0000;
  border-radius: 4px;
  outline: #0000;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
}

@media (min-width: 480px) {
  .fav-cock__btn {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .fav-cock__btn {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .fav-cock__btn {
    font-size: 20px;
    line-height: 1.2;
  }
}

.fav-cock__btn-more {
  color: var(--white-color);
  background-color: var(--btn-bg-color);
  padding-left: 38px;
  padding-right: 38px;
}

.fav-cock__btn-more:hover, .fav-cock__btn-more:focus {
  background-color: var(--btn-hover-color);
  color: var(--white-color);
}

.fav-cock__btn-fav {
  color: var(--btn-bg-color);
  background-color: var(--fav-cock-btn-fav-color);
  border: 1px solid var(--btn-outline-color);
}

.fav-cock__btn-fav:hover, .fav-cock__btn-fav:focus {
  outline: 1px solid var(--btn-hover-color);
  color: var(--btn-hover-color);
}

.fav-cocktail-container {
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 1280px) {
  .header {
    padding-bottom: 97px;
  }
}

.header__container {
  align-items: flex-end;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.header__logo {
  font-family: var(--title-font-family);
  color: var(--accent-color);
  font-size: 22px;
  font-weight: 400;
  line-height: 1.1;
}

@media (min-width: 768px) {
  .header__logo {
    font-size: 28px;
    line-height: .86;
  }
}

.header__nav-list {
  display: none;
}

@media (min-width: 1280px) {
  .header__nav-list {
    gap: 60px;
    margin-left: 43px;
    display: flex;
  }
}

.header__nav-item:hover, .header__nav-item:focus {
  color: #fd5103;
}

.header__nav-item {
  font-family: var(--title-font-family);
  letter-spacing: .02em;
  color: var(--manu-theme-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
}

.header__search {
  margin-left: 150px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .header__search {
    display: none;
  }
}

.header__search-btn {
  width: 40px;
  height: 40px;
  background-color: #0000;
  border: 1px;
  position: absolute;
  left: 0;
}

.header__search-border:after {
  content: "";
  width: 39px;
  height: 1px;
  background-color: var(--accent-color);
  position: absolute;
  top: 19px;
  left: 20px;
  transform: rotate(90deg);
}

.header__search-icon {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}

.header__search-input {
  width: 288px;
  height: 40px;
  background-color: var(--search-input-color);
  color: var(--input-text-color);
  border: 1px solid #fd5103;
  border-radius: 5px;
  outline: none;
  padding: 8px 8px 8px 48px;
}

.header__theme-list {
  display: none;
}

@media (min-width: 1280px) {
  .header__theme-list {
    gap: 12px;
    margin-left: 150px;
    display: flex;
  }
}

.header__theme-item {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
}

.header__theme-light {
  font-family: var(--title-font-family);
  color: var(--accent-theme-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.header__switcher {
  width: 40px;
  height: 20px;
  background: #fff;
  border: .604701px solid #fd5103;
  border-radius: 20px;
}

.header__theme-dark {
  font-family: var(--title-font-family);
  color: var(--theme-dark-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.btn__burger-menu-open {
  width: 40px;
  height: 40px;
  background-color: #0000;
  border: 0;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

@media (min-width: 1280px) {
  .btn__burger-menu-open {
    display: none;
  }
}

.menu-open__icon {
  fill: var(--btn-mob-menu-color);
  transition: fill var(--transition);
}

.menu-open__icon:hover, .menu-open__icon:focus {
  fill: var(--accent-text-color);
}

.toggle-button {
  width: 40px;
  height: 20px;
  vertical-align: top;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: .604701px solid #fd5103;
  border-radius: 20px;
  outline: none;
  margin: 0;
  transition: all .3s cubic-bezier(.2, .85, .32, 1.2);
  position: relative;
}

.toggle-button:after {
  content: "";
  width: 16px;
  height: 16px;
  background-color: var(--accent-color);
  border-radius: 50%;
  transition: all .3s cubic-bezier(.2, .85, .32, 1.2);
  position: absolute;
  top: 1px;
  left: 1px;
  transform: translateX(0);
}

.toggle-button:checked:after {
  background-color: #fff;
  transform: translateX(calc(100% + 3.9px));
}

.toggle-button:checked {
  background-color: var(--accent-color);
  border: .604701px solid #fff;
}

.login-btn {
  display: none;
}

@media (min-width: 1280px) {
  .login-btn {
    font-family: var(--title-font-family);
    background-color: var(--accent-color);
    color: #fff;
    margin-left: auto;
    padding: 5px 15px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33;
    display: block;
  }
}

.header__logo {
  position: relative;
}

.coctail-header {
  fill: #fd5103;
  z-index: -1;
  position: absolute;
  bottom: 11px;
  left: 78.8px;
}

@media (min-width: 768px) {
  .coctail-header {
    bottom: 11px;
    left: 100.5px;
  }
}

.drink-header {
  animation: myframes 3s cubic-bezier(.2, .2, .2, .2) forwards;
  position: absolute;
  bottom: 21px;
  left: 85.8px;
}

@media (min-width: 768px) {
  .drink-header {
    bottom: 23px;
    left: 109.5px;
  }
}

@keyframes myframes {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    transform: scale(0, .2)translateY(8px);
  }
}

.drink-white {
  z-index: -1;
  position: absolute;
  bottom: 21px;
  left: 85.8px;
}

@media (min-width: 768px) {
  .drink-white {
    bottom: 23px;
    left: 109.5px;
  }
}

.fav__dropdown-item {
  font-family: var(--primary-font-family);
  letter-spacing: .02em;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33px;
}

.fav__select {
  position: relative;
}

.fav__select-input {
  cursor: pointer;
}

.fav__list {
  border: 1px solid var(--accent-color);
  width: 210px;
  border-radius: 4px;
  position: absolute;
}

.fav__dropdown-item {
  text-align: center;
  height: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fav__nav-link {
  background-color: inherit;
  height: 48px;
  width: 100%;
  color: var(--fav-dropdown-item);
  border: 0;
  align-items: center;
  padding-left: 12px;
  display: flex;
}

.fav__nav-link:hover, .fav__nav-link:focus {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.section-hero {
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .section-hero {
    padding-top: 0;
    padding-bottom: 194px;
  }
}

@media (min-width: 1280px) {
  .section-hero {
    padding-bottom: 192px;
  }
}

.hero-container {
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  padding-right: 0;
  display: flex;
}

@media (min-width: 320px) {
  .hero-container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .hero-container {
    width: 768px;
    align-items: flex-start;
  }
}

@media (min-width: 1280px) {
  .hero-container {
    width: 1280px;
  }
}

.hero-container .section-content {
  text-align: start;
  flex-direction: column;
  place-content: flex-start;
  align-items: center;
  padding-top: 60px;
  padding-left: 20px;
  display: flex;
}

@media (min-width: 768px) {
  .hero-container .section-content {
    align-items: flex-start;
    padding-top: 60px;
    padding-left: 20px;
  }
}

@media (min-width: 1280px) {
  .hero-container .section-content {
    padding-top: 0;
    padding-left: 16px;
  }
}

.hero__title {
  font-family: var(--primary-font-family);
  color: var(--accent-color);
  max-width: 280px;
  font-size: 22px;
  font-weight: 600;
  line-height: 42px;
}

@media (min-width: 768px) {
  .hero__title {
    max-width: 336px;
  }
}

@media (min-width: 1280px) {
  .hero__title {
    max-width: 688px;
    font-size: 52px;
    line-height: 1.54;
  }
}

.hero__list {
  display: none;
}

@media (min-width: 768px) {
  .hero__list {
    width: 324px;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    place-items: center;
    display: flex;
  }
}

@media (min-width: 1280px) {
  .hero__list {
    width: 520px;
  }
}

.hero__item {
  cursor: pointer;
  transition: color var(--transition);
  box-sizing: border-box;
  width: 24px;
  height: 25px;
  color: var(--filter-letter-light-color);
  transition: transform var(--transition);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  display: block;
}

.hero__item:hover, .hero__item:focus {
  color: var(--accent-color);
  transform: scale(2);
}

@media (min-width: 1280px) {
  .hero__item {
    width: 40px;
    height: 40px;
    font-size: 24px;
  }
}

.hero__text {
  font-family: var(--primary-font-family);
  letter-spacing: .02em;
  color: var(--hero-text-color);
  opacity: .8;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  display: none;
}

@media (min-width: 768px) {
  .hero__text {
    font-size: 14px;
    line-height: 2;
    display: block;
  }
}

@media (min-width: 1280px) {
  .hero__text {
    font-size: 18px;
    line-height: 1.56;
  }
}

.hero__text.mob {
  color: var(--hero-text-color);
  text-align: center;
  margin-top: 28px;
  margin-bottom: 20px;
  display: block;
}

@media (min-width: 768px) {
  .hero__text.mob {
    display: none;
  }
}

.hero-button {
  min-width: 93px;
  cursor: pointer;
  border: 1px solid var(--accent-color);
  text-align: start;
  background-color: var(--hero-bg-input-color);
  font-family: var(--primary-font-family);
  color: var(--accent-color);
  transition: color var(--transition), background-color var(--transition);
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.35;
  display: flex;
}

@media (min-width: 768px) {
  .hero-button {
    display: none;
  }
}

.picture-section {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-left: 20px;
  display: flex;
}

@media (min-width: 768px) {
  .picture-section {
    position: absolute;
    top: 131px;
    right: 0;
  }
}

@media (min-width: 1280px) {
  .picture-section {
    top: 100px;
    right: 0;
  }
}

.app {
  justify-content: center;
  margin-top: 0;
  display: flex;
}

@media (min-width: 768px) {
  .app {
    display: none;
  }
}

.select {
  width: 100%;
  position: relative;
}

.select.open .select__dropdown {
  display: block;
}

.select__input {
  z-index: 1;
  width: 93px;
  height: 34px;
  border: 1px solid var(--accent-color);
  cursor: pointer;
  text-align: start;
  background-color: var(--select-input-color);
  font-family: var(--primary-font-family);
  color: var(--accent-color);
  transition: color var(--transition), background-color var(--transition);
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.35;
  display: flex;
}

.hero__icon {
  fill: var(--accent-color);
}

.select__input-checked {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.select__input-checked .hero__icon {
  fill: var(--white-color);
}

.select__dropdown {
  width: 94px;
  border: 1px solid var(--accent-color);
  max-height: 84px;
  border-radius: 4px;
  margin: 0 auto;
  position: absolute;
  top: 34px;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.select ::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}

.select ::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.select ::-webkit-scrollbar-thumb {
  background: #fd5103;
  border-radius: 4px;
}

.select__list {
  padding-left: 10px;
}

.select__item {
  height: 28px;
  font-family: var(--primary-font-family);
  color: var(--medium-grey-color);
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

.input-span {
  padding-left: 10px;
}

.hero__img {
  width: 100%;
}

@media (min-width: 480px) {
  .hero__img {
    width: 100%;
  }
}

.sorry {
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  display: flex;
}

.sorry__title {
  font-family: var(--title-font-family);
  text-align: center;
  color: var(--title-light-color);
  width: 280px;
  margin-bottom: 63px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.91;
}

@media (min-width: 768px) {
  .sorry__title {
    width: 331px;
    font-size: 32px;
    line-height: 1.31;
  }
}

@media (min-width: 1280px) {
  .sorry__title {
    width: 493px;
    font-size: 48px;
    line-height: 1.5;
  }
}

.sorry__picture {
  justify-content: center;
  align-items: center;
  display: flex;
}

.pulse {
  animation: pulse 2s infinite;
  box-shadow: 0 0 #fd510366;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 #fd5103;
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px #fd5103;
  }

  100% {
    -webkit-box-shadow: 0 0 #fd5103;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 #fd510366;
  }

  70% {
    box-shadow: 0 0 0 10px #fd510300;
  }

  100% {
    box-shadow: 0 0 #fd510300;
  }
}

.gallery {
  font-family: var(--gallery-font-family);
}

.gallery-section {
  margin-bottom: 55px;
}

.gallery__title {
  text-align: center;
  color: var(--title-light-color);
  margin-bottom: 60px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.91;
}

@media (max-width: 1279.98px) {
  .gallery__title {
    margin-top: 60px;
  }
}

@media (min-width: 480px) {
  .gallery__title {
    font-size: 32px;
    font-weight: 700;
  }
}

@media (min-width: 768px) {
  .gallery__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.31;
  }
}

@media (min-width: 1280px) {
  .gallery__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.5;
  }
}

.gallery__list {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 768px) {
  .gallery__list {
    --card-item: 2;
    --card-gap: 20px;
  }
}

@media (min-width: 1280px) {
  .gallery__list {
    --card-item: 3;
    --card-gap: 20px;
  }
}

.gallery__img {
  width: 100%;
  border-radius: 20px 20px 0 0;
  margin-bottom: 20px;
  display: block;
}

.gallery__list-name {
  text-align: center;
  color: var(--title-light-color);
  margin-bottom: 25px;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.2;
}

@media (min-width: 480px) {
  .gallery__list-name {
    margin-bottom: 25px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .gallery__list-name {
    margin-bottom: 25px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .gallery__list-name {
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: 900;
    line-height: 1.2;
  }
}

.gallery__list-item {
  border: 1px solid var(--accent-color);
  box-sizing: border-box;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.gallery__list-item:hover, .gallery__list-item:focus {
  box-shadow: -10px 10px 20px #acabab, 10px -10px 20px #fff;
}

.gallery__list-ingr {
  justify-content: space-between;
  display: flex;
}

@media (min-width: 768px) {
  .gallery__list-ingr {
    --card-item: 2;
    --card-gap: 20px;
  }
}

@media (min-width: 1280px) {
  .gallery__list-ingr {
    --card-item: 3;
    --card-gap: 20px;
  }
}

@media (min-width: 480px) {
  .gallery__list-ingr .gallery__list-item {
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .gallery__list-ingr .gallery__list-item {
    margin-bottom: 28px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .gallery__list-ingr .gallery__list-item {
    max-width: 395px;
    margin-bottom: 28px;
    font-size: 32px;
    font-weight: 900;
    line-height: 1.2;
  }
}

.gallery__list-ingr .gallery__list-name {
  text-align: inherit;
  color: var(--gallery-title-color);
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.6;
}

@media (min-width: 480px) {
  .gallery__list-ingr .gallery__list-name {
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .gallery__list-ingr .gallery__list-name {
    margin-bottom: 28px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .gallery__list-ingr .gallery__list-name {
    margin-bottom: 28px;
    font-size: 32px;
    font-weight: 900;
    line-height: 1.2;
  }
}

.gallery__list-ingr .gallery__list-type {
  font-family: var(--title-font-family);
  text-transform: uppercase;
  color: var(--gallery-subtitle-color);
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}

@media (min-width: 480px) {
  .gallery__list-ingr .gallery__list-type {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  .gallery__list-ingr .gallery__list-type {
    padding-bottom: 28px;
    font-size: 16px;
    font-weight: 900;
    line-height: 1.5;
  }
}

@media (min-width: 1280px) {
  .gallery__list-ingr .gallery__list-type {
    padding-bottom: 28px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }
}

.gallery__btn-container {
  justify-content: space-evenly;
  padding: 28px;
  display: flex;
}

@media (min-width: 480px) {
  .gallery__btn-container {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .gallery__btn-container {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .gallery__btn-container {
    padding: 28px;
    font-size: 20px;
    line-height: 1.2;
  }
}

.gallery__btn {
  text-align: center;
  letter-spacing: .02em;
  border-color: #0000;
  border-radius: 4px;
  outline: #0000;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
}

@media (min-width: 480px) {
  .gallery__btn {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .gallery__btn {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .gallery__btn {
    font-size: 20px;
    line-height: 1.2;
  }
}

.gallery__btn-more {
  color: var(--white-color);
  background-color: var(--btn-bg-color);
  padding: 12px 27px;
}

.gallery__btn-more:hover, .gallery__btn-more:focus {
  background-color: var(--btn-hover-color);
  color: var(--white-color);
}

.gallery__btn-fav {
  color: var(--btn-bg-color);
  background-color: var(--gallery-btn-fav-color);
  border: 1px solid var(--btn-outline-color);
  padding: 12px 10px;
}

.gallery__btn-fav:hover, .gallery__btn-fav:focus {
  outline: 1px solid var(--btn-hover-color);
  color: var(--btn-hover-color);
}

.gallery__btn-fav .gallery__btn-fav-svg {
  fill: var(--heart-color);
  stroke: var(--btn-bg-color);
  stroke-width: 2px;
}

.gallery__btn-fav.is-checked .gallery__btn-fav-svg {
  fill: var(--accent-color);
}

.mob-open {
  color: #0000;
}

.ing-card-wrap {
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.ing-card-wrap-modal {
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.footer {
  text-align: center;
  height: 110px;
  background-color: var(--accent-color);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.footer__text {
  font-family: var(--gallery-font-family);
  letter-spacing: .02em;
  color: var(--white-color);
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

@media (min-width: 480px) {
  .footer__text {
    width: 380px;
  }
}

.footer__wrap {
  text-transform: uppercase;
}

.footer__link {
  color: var(--white-color);
  background-color: #0000;
  border: 0;
  text-decoration: underline;
}

.back-to-top {
  cursor: pointer;
  opacity: 0;
  border-radius: 50%;
  padding: 10px 12px;
  transition: all .3s;
  position: fixed;
  bottom: -25px;
  right: -13px;
}

@media (min-width: 768px) {
  .back-to-top {
    right: -1px;
  }
}

.back-to-top.active {
  opacity: 1;
  fill: var(--accent-color);
  transition: all .3s;
  bottom: 100px;
}

/*# sourceMappingURL=index.5079442f.css.map */
