:root {
  --title-font-family: 'Poppins', sans-serif;
  --primary-font-family: 'Poppins', sans-serif;
  --gallery-font-family: 'Roboto', sans-serif;

  --light-bg-color: #fcfcfc;
  --dark-bg-color: #202025;

  --white-color: #fcfcfc;
  --black-color: #000000;
  --medium-grey-color: #5f6775;

  --title-light-color: #202025;
  --title-dark-color: #fcfcfc;

  --filter-letter-light-color: #5f6775;
  --filter-letter-dark-color: #5f6775;

  --modal-text-light-color: #5f6775;
  --modal-text-dark-color: #fcfcfc;
  --modal-title--black: #000917;

  --btn-bg-color: #fe7031;
  --btn-inmodal-bg-color: #fd5103;

  --btn-text-color: #fd5103;
  --btn-outline-color: #ff8650;

  --pretitle-hero-color: #fd5103;

  --accent-color: #fd5103;
  --btn-hover-color: #fd5103;

  --card-item: 1;
  --card-gap: 20px;
  --transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);

  //theme
  --body-background-color: #FFFFFF;
  --accent-theme-color: #fd5103;
  --theme-dark-color: #5f6775;
  --manu-theme-color: #202025;
  --search-input-color: #FFFFFF;
  --input-text-color: #202025;
  --btn-mob-menu-color: #202025;
  --fav-dropdown-item: #202025;

  --hero-text-color: #202025;
  --hero-bg-input-color: #fcfcfc;
  --select-input-color: #fcfcfc;

  --gallery-btn-fav-color: #ffffff;
  --gallery-title-color: #000000;
  --gallery-subtitle-color: #5F6775;
  --modal-bg-color: #ffffff;
  --modal-title-color: #000917;
  --modal-text-color: #5f6775;
  --modal-accent-text-color: #000000;
  --background-color: rgba(0, 0, 0, 0.5);
  --modal-close-icon-color: #202025;

  --mob-menu-bg-color: #fcfcfc;
  --menu-mob-color:#202025;
  --heart-color: #fcfcfc;
}
