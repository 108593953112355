.modal__bg {
  display: none;
  //background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modal__annoy {
  
    background-color: #fff;
  position: absolute;
  width: 300px;
  //top: 50%;
  //left: 50%;
  bottom: 40px;
  right: 10px;
  //transform: translate(-50%, -50%);
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //box-shadow: 0 2px 10px rgba(0,0,0,0.2), 0 5px 5px rgba(0,0,0,0.2);
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
  font-size: 1.5rem;
  animation-name: animatemodal;
  animation-duration: 0.4s;
  
  
  @include mq(fab) {
  right: 40px;
  width: 400px;
}
  
}

.close__btn {
  align-self: flex-end;
}

.close__btn:hover {
  cursor: pointer;
  color: #ff0000;
}

.modal__img-soldier {
  margin-bottom: 15px;
}

.modal__img-heart {
  @include mq(mob) {
    width: 30px;
    height: 30px;
    }
  margin-left: 7px;
}

.modal-annoy__text {
  display: flex;
 font-family: var(--primary-font-family);
  font-size: 16px;
  color: black;
  margin-bottom: 15px;
}

.modal-annoy__link {
  font-family: var(--primary-font-family);
  font-size: 14px;
  text-decoration: underline;
  color: #212121;
  margin-bottom: 30px;
}

.modal-annoy__link:hover {
  color: #fd5103;
}

@keyframes animatemodal {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 20px;
    opacity: 1;
  }
}
