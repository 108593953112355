.backdrop-sign-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 250ms linear, visibility 250ms linear;
  }
  
  .backdrop-sign-in .visually-hidden .modal {
    transform: translate(-50%, -50%) scale(0) rotate(-180deg);
  }
  
  .modal {
    border-radius: 20%;
    position: absolute;
    top: 93px;
    left: 50%;
    transform: translate(-50%) scale(1) rotate(0);
    background-color: #fff;
    transition: cubic-bezier(0.4, 0, 0.2, 1);
    padding: 40px;
    @media screen and (min-width: 768px) {
      top: 208px;
    }
    @media screen and (min-width: 1200px) {
      top: 221px;
      padding: 50px 40px;
    }
  }
  
  .modal__close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    transition: var(--transition);
    &:hover,
    &:focus {
      fill: var(--white-color);
      background-color: var(--accent-color);
    }
  }
  
  .modal__title {
    font-family: var(--title-font-family);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.03em;
    color: #212121;
    margin-bottom: 12px;
    @media screen and (min-width: 1200px) {
      font-size: 20px;
      line-height: 1.15;
    }
  }
  
  .modal-label {
    display: flex;
    flex-direction: column;
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  
  .modal-label__text {
    font-family: var(--font-main);
    font-weight: 400;
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.01em;
    color: #757575;
    margin-bottom: 4px;
  }
  
  .modal-label__input {
    padding: 5px 20px;
    border: 1px solid rgba(33, 33, 33, 0.2);
    border-radius: 4px;
    outline: none;
    transition: border var(--transition);
    width: 250px;
    height: 30px;
    &:hover,
    &:focus {
      border: 1px solid var(--accent-color);
      cursor: pointer;
      outline: none;
    }
  }
  
  .modal-policy {
    margin-bottom: 30px;
  }
  
  .modal-policy__label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .modal-policy__text {
    font-family: var(--font-main);
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.03em;
    color: #757575;
    @media screen and (min-width: 1200px) {
      font-size: 14px;
      line-height: 1.71;
    }
  }
  
  .modal-policy__link {
    color: var(--accent-color);
    text-decoration: none;
    border-bottom: 1px solid var(--accent-color);
  }
  
  .modal-policy__custom-check {
    border: 2px solid #212121;
    border-radius: 3px;
    width: 16px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
  }
  
  .modal-policy__check {
    transition: border var(--transition);
    &:checked + .modal-policy__custom-check {
      border: 2px solid var(--accent-color);
      background-color: var(--accent-color);
    }
    &:hover + .modal-policy__custom-check,
    &:focus + .modal-policy__custom-check {
      border: 2px solid var(--accent-color);
    }
  }
  
  .sign-in-btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .modal-button-sign-in {
    padding: 5px 10px;
    font-family: var(--font-main);
    background-color: var(--accent-color);
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.88;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.06em;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    border-radius: 4px;
    border: 0px;
    transition: background-color var(--transition);
    &:hover,
    &:focus {
      background-color: var(--accent-color);
      box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.08),
        0px 2px 2px rgba(0, 0, 0, 0.12);
    }
  }
  